export const NAV_LINKS =[
    {
        label: 'Home',
    },
    {
        label: 'Services',
    },
    {
        label: 'Join Us',
    },
]