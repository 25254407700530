export const ServicesCardDetails = [
    {
        title: 'Packers And Movers',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor repudiandae debitis repellat eius laboriosam iusto itaque consectetur nobis blanditiis omnis.',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ]
    },
    {
        title: 'Packers And Movers',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor repudiandae debitis repellat eius laboriosam iusto itaque consectetur nobis blanditiis omnis.',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ]
    },
    {
        title: 'Packers And Movers',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor repudiandae debitis repellat eius laboriosam iusto itaque consectetur nobis blanditiis omnis.',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ]
    },
    {
        title: 'Packers And Movers',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor repudiandae debitis repellat eius laboriosam iusto itaque consectetur nobis blanditiis omnis.',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ]
    }
]

export const JoinUsDetails = [
    {
        title: 'As Driver',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor repudiandae debitis repellat eius laboriosam iusto itaque consectetur nobis blanditiis omnis.',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ]
    },
    {
        title: 'As User',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor repudiandae debitis repellat eius laboriosam iusto itaque consectetur nobis blanditiis omnis.',
        points: [
            'Lorem ipsum dolor',
            'Lorem ipsum dolor',
            'Lorem ipsum dolor'
        ]
    }
]